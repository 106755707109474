.sprite {
	background-repeat: no-repeat;
	display: inline-block;
	background-image: url('../img/sprites/png/png-sprite.png');
	background-size: 91px 87px;
	&--alfabank-icon {
		background-position: 0px -68px;
		width: 12px;
		height: 19px;
	}
	&--basket-icon {
		background-position: 0px -32px;
		width: 38px;
		height: 15px;
	}
	&--basket-pic {
		background-position: -32px 0px;
		width: 38px;
		height: 17px;
	}
	&--basket {
		background-position: 0px 0px;
		width: 30px;
		height: 30px;
	}
	&--enter-pic {
		background-position: -72px 0px;
		width: 19px;
		height: 22px;
	}
	&--fb-icon-hover {
		background-position: -49px -49px;
		width: 8px;
		height: 13px;
	}
	&--fb-icon {
		background-position: -58px -32px;
		width: 8px;
		height: 13px;
	}
	&--in-icon-hover {
		background-position: -34px -49px;
		width: 13px;
		height: 13px;
	}
	&--in-icon {
		background-position: -19px -49px;
		width: 13px;
		height: 13px;
	}
	&--loop-icon {
		background-position: -40px -32px;
		width: 16px;
		height: 13px;
	}
	&--out-icon {
		background-position: -72px -24px;
		width: 19px;
		height: 22px;
	}
	&--tick-icon {
		background-position: 0px -49px;
		width: 17px;
		height: 17px;
	}
	&--vk-icon-hover {
		background-position: -32px -19px;
		width: 16px;
		height: 9px;
	}
	&--vk-icon {
		background-position: -50px -19px;
		width: 16px;
		height: 9px;
	}
}
