@font-face {
    font-family: "elektra_text_proregular";
    src: url('../fonts/elektratextlight.woff2') format('woff2'),
         url('../fonts/elektratextlight.woff') format('woff'),
         url('../fonts/elektratextlight.ttf') format('truetype'),
         url('../fonts/elektratextlight.svg#elektra_light_proregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "elektra_medium_probold";
    src: url('../fonts/elektra-medium-pro-bold.woff2') format('woff2'),
         url('../fonts/elektra-medium-pro-bold.woff') format('woff'),
         url('../fonts/elektra-medium-pro-bold.ttf') format('truetype'),
         url('../fonts/elektra-medium-pro-bold.svg#elektra_medium_probold') format('svg');
    font-weight: normal;
    font-style: normal;
}

body {
	font-family: "elektra_text_proregular";
	background: #ffffff;
	overflow-y: scroll;
	overflow-x: hidden;
}

.container {
	width: 940px;
	margin: 0 auto;
}

html {
	position: relative;
	min-height: 100%;
}

.clearfix {
	&:before,
	&:after {
		content: " "; // 1
		display: table; // 2
	}
	&:after {
		clear: both;
	}
}

.g_hidden {
	display: none;
}

a {
	color: #47A7C8;
}

/* HEADER */

.header {
	padding: 30px 0;
	background: #ffffff;
	z-index: 1;
	position: relative;
	transition: all 0.3s ease;
	top: 0;

	.header-logo {
		margin-right: 20px;
		float: left;

		&__brand {
			color: #656668;
			font-size: 14px;
			font-weight: 400;
			float: left;
			margin-top: 10px;
			font-family: 'elektra_text_proregular';
		}

	}

	&__enter-wrap {
		float: right;
		font-size: 0;
		width: 400px;
		text-align: right;
	}

	&__enter {
		font-family: 'elektra_text_proregular';
		font-size: 14px;
		font-weight: 400;
		color: #656668;
		text-decoration: none;
		display: inline-block;
		vertical-align: middle;

		&:hover {
			color: #ed4e3b;
		}
	}

	&__enter-icon {
		display: inline-block;
		vertical-align: middle;
		margin-top: 2px;
		margin-right: 5px;
	}

	&__profile {
		display: inline-block;
		vertical-align: top;
		width: 75%;
		font-size: 0;
	}

	&__profile-img {
		display: inline-block;
		width: 40px;
		vertical-align: middle;
		margin-right: 5px;

		img {
			display: block;
			border-radius: 50%;
			overflow: hidden;
		}
	}

	&__profile-username {
		font-size: 14px;
		color: #656668;
		font-weight: 400;
		vertical-align: middle;
		display: inline-block;
		line-height: 40px;
		text-decoration: none;
	}

	&__logout {
		display: inline-block;
		vertical-align: top;
		width: 25%;
		vertical-align: top;
		padding-top: 7px;
	}

	&__wrap {
		margin-bottom: 25px;
	}

	&__basket-wrap {
		position: relative;
		display: inline-block;
		vertical-align: top;

		&:hover {
			.header__basket-dropdown {
			display: block;
			}
		}
	}

	&__basket {
		border-radius: 3px;
		padding: 16px 15px;
		border: 1px solid #aeb0b4;
		position: relative;
		width: 50px;
		height: 49px;
		display: block;

		&:hover {
			background: #ffddde;
		}

		// &--full {
		// 	&:before {
		// 		position: absolute;
		// 		content: '';
		// 		top: 0;
		// 		left: 0;
		// 		width: 10px;
		// 		height: 10px;
		// 		background: #000;
		// 	}
		// }
	}

	&__basket-icon {
		display: inline-block;
		position: absolute;
		left: 50%;
		top: 16px;
		margin-left: -19px;
	}

	&__dropdown-wrap {
		position: absolute;
		top: 100%;
		right: -1px;
		width: 400px;
	}

	&__basket-dropdown {
		border: 1px solid #aeb0b4;
		box-shadow: 0 3px 7px 0 rgba(0,0,0,.13);
		font-size: 14px;
		// font-family: elektra_medium_probold;
		color: #1a1c20;
		font-weight: 700;
		display: none;
		margin-top: 25px;
		background: #fff;
	}

	&__basket-message {
		display: inline-block;
		line-height: 53px;
		font-size: 14px;
	}

	#result_edit_miniCart {
		padding: 20px;
		border-bottom: 1px solid #aeb0b4;
	}

	.result_miniCart_item {
		margin-bottom: 10px;
		position: relative;

		.result_miniCart_remove {
			position: absolute;
			z-index: 1;
			top: -2px;
			right: 0;
			width: 15px;
			height: 15px;
			padding: 0;
			cursor: pointer;
			outline: 0;
			background-color: transparent;

			&:hover:after {
				background: #ee393e;
			}

			&:hover:before {
				background: #ee393e;
			}
		}

		.result_miniCart_remove:after, .result_miniCart_remove:before {
			position: absolute;
			top: 6px;
			right: 0;
			width: 15px;
			height: 2px;
			content: '';
			opacity: .5;
			background: #202226;
		}

		.result_miniCart_remove:before {
			transform: rotate(-45deg);
		}

		.result_miniCart_remove:after {
			transform: rotate(45deg);
		}

	}

	.result-list {
		font-size: 0;
	}

	.product-image {
		display: inline-block;
		width: 100px;
		vertical-align: top;

		.image {
			display: block;
		}
	}

	.product-body {
		display: inline-block;
		width: 258px;
		vertical-align: top;
		padding: 20px 10px;
	}

	.product-name {
		font-family: 'elektra_text_proregular';
		font-size: 14px;
		color: #36849e;
		font-weight: 400;
		margin-bottom: 5px;
		display: block;

		&:hover {

		}
	}

	.whishlist-price {
		font-family: 'elektra_text_proregular';
		font-size: 14px;
		color: #1a1c20;
		font-weight: 400;
	}
	.product-price {
		font-family: 'elektra_text_proregular';
		font-size: 14px;
		color: #1a1c20;
		font-weight: 400;
		background: transparent;
		padding: 0;
		height: auto;
		width: auto;
	}
}

.menu-cart-total {
	padding: 20px 10px;
	text-align: center;
	border-bottom: 1px solid #aeb0b4;

	.cart-count {
		font-size: 14px;
		// font-family: 'elektra_medium_probold';
		color: #1a1c20;
		font-weight: 700;
		display: inline-block;
		vertical-align: top;
	}

	.cart-price {
		font-size: 14px;
		// font-family: 'elektra_medium_probold';
		color: #1a1c20;
		font-weight: 700;
		background: transparent;
		width: auto;
		padding: 0;
		height: auto;
	}
}

.cart-number {
	font-size: 12px;
	color: #fff;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #ed4e3b;
	position: absolute;
	text-align: center;
	top: -8px;
  right: -6px;
	padding-top: 4px;
	display: none;
}

.cart-action {
	padding: 20px;
	.cart-btn__link {
		width: 100px;
		margin-right: 10px;

		&--transparent {
			background: #fff;
			color: #656668;
			display: inline-block;
			border: 1px solid #656668;
			width: 100px;
			height: 45px;
			border-radius: 3px;
			text-decoration: none;
			line-height: 45px;
			text-align: center;
			vertical-align: top;

			&:hover {
				background: #ed4e3b;
				border: 1px solid #ed4e3b;
				color: #fff;
			}
		}
	}
}

.search {
	font-size: 0;
	margin-bottom: 15px;

	&__field {
		width: 700;
		box-sizing: border-box;
		font-size: 0;
		margin-right: 20px;
		display: inline-block;
		vertical-align: top;
		position: relative;
	}

	&__text {
		padding: 15px 15px 15px 30px;
		border-radius: 3px;
		border: 1px solid #aeb0b4;
		width: 625px;
		display: inline-block;
		vertical-align: top;
		font-size: 14px;
		font-family: 'elektra_text_proregular';
		color: #656668;
		font-weight: 400;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		border-right: 0;
		color: #656668;

		// &:focus + .search__icon{
		// 	display: none;
		// }
	}

	&__icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 10px;
	}

	&__submit {
		width: 75px;
		display: inline-block;
		vertical-align: top;
		font-size: 14px;
		font-family: 'elektra_text_proregular';
		color: #656668;
		font-weight: 400;
		background: transparent;
		padding: 15px;
		border-radius: 3px;
		border: 1px solid #aeb0b4;
		z-index: 1;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;

		&:hover {
			background: #ffddde;
		}
	}

	&__btn-wrap {
		border-radius: 3px;
		background: #ed4e3b;
		width: 155px;
		display: inline-block;
		vertical-align: top;
		margin-right: 15px;

		&:hover {
			background: #fe7c6d;
		}
	}

	&__btn {
		color: #ffffff;
		font-size: 14px;
		font-family: 'elektra_text_proregular';
		position: relative;
		text-decoration: none;
		display: block;
		padding: 16px 15px;

		&:before {
			content: "";
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 15px;
			width: 15px;
			height: 10px;
			border-top: 6px double #fff;
			border-bottom: 2px solid #fff;
		}
	}

}

/* MAIN */

.main {
	padding-bottom: 200px;
	transition: all 0.3s ease;
	position: relative;
	top: 0;

	&__content {
		h1 {
			color: #1a1c20;
			font-size: 24px;
			font-weight: 700;
			margin-bottom: 25px;
		}

		h2 {
			color: #1a1c20;
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 15px;
			text-align: left;
		}

		p {
			color: #656668;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			text-align: left;
		}

		.success-title {
			margin-bottom: 20px;
			font-size: 16px;
			font-weight: 700;
		}

		.success-text {
			font-size: 18px;
			text-align: left;
			color: #656668;
			font-weight: 400;
			margin-bottom: 35px;
		}

		#catalog {
			display: none;
		}
	}

	/* BREADCRUMBS */

	.breadcrumbs {
		font-size: 0;
		list-style: none;
		margin-bottom: 60px;

		&__item {
			font-family: 'elektra_text_proregular';
			font-weight: 400;
			font-size: 14px;
			color: #656668;
			margin-right: 8px;
			display: inline-block;
		}

		&__link {
			text-decoration: none;
			font-family: 'elektra_text_proregular';
			font-weight: 400;
			font-size: 14px;
			color: #656668;
		}
	}

	&__title {
		font-size: 24px;
		color: #1a1c20;
		font-weight: 700;
		// font-family: 'elektra_medium_probold';
		text-align: left;
		margin-bottom: 25px;
	}

	#catalog {
		padding-left: 45px;
	}

	&__tabs {
		height: 100%;
		width: 100%;
		text-align: center;
		list-style: none;
		padding: 0;
		position: relative;
		line-height: 35px;
		margin: 0 auto;
    border-bottom: 1px solid #aeb0b4;
	}

	&__tabs-link {
		float: left;
		padding: 10px 40px;
		text-decoration: none;
    border-radius: 3px 3px 0 0;
		border: 1px solid #fff;
		border-bottom: 1px solid #aeb0b4;
	}

	&__tabs-item {
		float: left;
		position: relative;
		margin-bottom: -1px;
		display: block;

		&--purpose {

			&.active-tab:before {
				width: 6px;
				left: -5px;
				border: 1px solid #aeb0b4;
				border-bottom-right-radius: 3px;
				border-width: 0px 1px 1px 0px;
				z-index: 1;
				position: absolute;
				bottom: 0;
				height: 6px;
				content: " ";
			}
		}

		&.active-tab {
			&:after {
				z-index: 0;
				position: absolute;
				bottom: 0;
				width: 6px;
				height: 6px;
				content: " ";
				right: -5px;
				border: 1px solid #aeb0b4;
				border-bottom-left-radius: 3px;
				border-width: 0 0 1px 1px;
			}

			.main__tabs-link {
				border: 1px solid #aeb0b4;
				border-bottom-color: #fff;
			}
		}
	}

	&__tabs-title {
		font-size: 24px;
		color: #1a1c20;
		font-weight: 700;
		// font-family: 'elektra_medium_probold';
		display: block;
		text-align: left;
		line-height: normal;
	}

	&__tabs-desc {
		font-family: 'elektra_text_proregular';
		font-size: 14px;
		color: #656668;
		text-align: left;
		display: block;
		line-height: normal;
	}

	&__catalog-wrap {
		padding: 45px 0;
	}

	&__catalog {
		font-size: 0;
		min-height: 550px;
	}

	&__catalog-item {
		display: inline-block;
		width: 33.33%;
		box-sizing: border-box;
		padding-right: 80px;
		margin-bottom: 25px;
		vertical-align: top;
	}

	&__catalog-img {
		margin-bottom: 10px;
		height: 50px;
	}

	&__catalog-name {
		font-size: 14px;
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		color: #1a1c20;
		margin-bottom: 10px;
		text-align: left;
	}

	&__catalog-populars {
		font-family: 'elektra_text_proregular';
		font-size: 14px;
		color: #656668;
		font-weight: 400;
		margin-bottom: 10px;
	}

	&__catalog-link {
		display: block;
		text-decoration: none;
	}

	&__catalog-popular {
		display: inline-block;
		font-family: 'elektra_text_proregular';
		font-size: 14px;
		color: #656668;
		font-weight: 400;

		&:hover {
			color: #ed4e3b;
		}
	}

	&__catalog-seeall {
		margin-bottom: 10px;
	}

	.all-link {
		font-family: 'elektra_text_proregular';
		font-size: 13px;
		color: #656668;
		font-weight: 400;
		position: relative;

		&:hover {
			color: #ed4e3b;
		}

		&:after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: -15px;
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 3.5px 0 3.5px 4px;
			border-color: transparent transparent transparent #656668;
		}
	}

	&__filter-wrap {
		font-size: 0;
		margin-bottom: 30px;
	}
}

.purpose {
	font-size: 0;

	&__left {
		width: 25%;
		display: inline-block;
		vertical-align: top;
		padding-right: 20px;
		box-sizing: border-box;
	}

	&__img-wrap {
		display: block;
		margin: 0 auto;
		text-align: center;
		height: 100%;
		position: relative;
	}

	&__img {
		position: absolute;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		left: 50%;
	}

	&__right {
		width: 75%;
		font-size: 0;
		display: inline-block;
		vertical-align: top;
	}

	&__item {
		display: inline-block;
		vertical-align: top;
		position: relative;

		&:hover {
			box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.37);
		}

		&--business {
			display: block;
			box-sizing: border-box;
			background: #c8f1ff;
			height: 460px;
		}

		&--design {
			width: 460px;
			box-sizing: border-box;
			background: #ffd5d6;
			height: 220px;
			margin-right: 20px;
			margin-bottom: 20px;
		}

		&--it {
			width: 225px;
			box-sizing: border-box;
			background: #c8f1ff;
			height: 220px;
		}

		&--projection {
			width: 225px;
			box-sizing: border-box;
			background: #ffefa9;
			height: 220px;
			margin-right: 20px;
			margin-bottom: 20px;
		}

		&--office {
			width: 460px;
			box-sizing: border-box;
			background: #c8f1ff;
			height: 220px;
		}
	}

	&__name {
		position: absolute;
		bottom: 20px;
		left: 20px;
		font-size: 14px;
		// font-family: 'elektra_medium_probold';
		color: #1a1c20;
		font-weight: 700;
	}


}

/* Tabs */

.card {
	margin-bottom: 10px;
	&__title {
		font-size: 24px;
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		color: #1a1c20;
		margin-bottom: 25px;
		text-align: left;
	}

	&__item {
		border: 1px solid #aeb0b4;
		padding: 20px;
		margin-bottom: 20px;
		position: relative;
		font-size: 0;
		height: 240px;

		&:hover {
			box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.14);
		}

		.productRate {
			bottom: 70px;
			position: absolute;
			left: 0;
		}
	}

	&__mark-wrap {
		position: absolute;
		top: 20px;
		left: -1px;
		width: 80px;

		div {
			margin-bottom: 10px;
		}
	}

	&__mark-sale {
		background: #ed4e3b;
		color: #fff;
		font-family: 'elektra_text_proregular';
		font-size: 13px;
		font-weight: 400;
		padding: 15px;
		text-align: center;
		text-transform: uppercase;
	}

	&__mark-outlet {
		background: #ed4e3b;
		color: #fff;
		font-family: 'elektra_text_proregular';
		font-size: 13px;
		font-weight: 400;
		padding: 15px;
		text-align: center;
		text-transform: uppercase;
	}

	&__mark-new {
		background: #ed4e3b;
		color: #fff;
		font-family: 'elektra_text_proregular';
		font-size: 13px;
		font-weight: 400;
		padding: 15px;
		text-align: center;
		text-transform: uppercase;
	}

	&__img {
		width: 200px;
		height: 200px;
		overflow: hidden;
		margin-right: 15px;
		display: inline-block;
		vertical-align: top;
	}

	&__text {
		width: 465px;
		display: inline-block;
		vertical-align: top;
	}

	&__basket {
		width: 215px;
		display: inline-block;
		vertical-align: top;
		width: 230px;
		position: absolute;
		height: 100%;
		top: 0;
		right: 0;
	}

	&__item-name {
		font-family: 'elektra_text_proregular';
		font-size: 16px;
		color: #36849e;
		font-weight: 400;
		margin-bottom: 15px;
		display: block;

		&:hover {
			color: #ed4e3b;
		}
	}

	&__info {
		list-style: none;
		margin-bottom: 35px;
	}

	&__info-item {
		font-family: 'elektra_text_proregular';
		font-size: 14px;
		color: #1a1c20;

		a {
			font-family: 'elektra_text_proregular';
			font-size: 14px;
			color: #1a1c20;
			text-decoration: none;

			&:hover {
				color: #ed4e3b;
			}
		}
	}

	&__btn-wrap {
		display: inline-block;
		text-align: center;
		background: #ecf9fd;
		width: 210px;
		position: absolute;
		bottom: 20px;
		right: 20px;
	}

	&__btn {
		font-size: 14px;
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		color: #1a1c20;
		text-decoration: none;
		padding: 10px;
		display: block;
		border: 1px solid #a4a4a4;
		border-radius: 3px;
		width: 100%;
		background: none;

		&:hover {
			background: #ed4e3b;
			color: #fff;
			border: 1px solid #ed4e3b;
		}
	}

	&__rating {
		position: absolute;
		bottom: 100px;
		right: 20px;
		text-align: left;
		display: block;
	}

	&__viewall-wrap {
		text-align: center;
	}

	&__viewall-link {
		border: 1px solid #a4a4a4;
		border-radius: 3px;
		font-size: 14px;
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		color: #1a1c20;
		text-decoration: none;
		padding: 15px;
		display: block;

		&:hover {
			background: #ecf9fd;
		}
	}

}

.old-price {
	font-family: 'elektra_text_proregular';
	font-size: 14px;
	color: #1a1c20;
}

.new-price {
	font-size: 24px;
	// font-family: 'elektra_medium_probold';
	font-weith: 700;
	color: #1a1c20;
}

/* Star-rating */

.star-rating {
	display: inline-block;
}

/* FOOTER */

.footer {
	background: #f4f6f9;
	padding-top: 20px;
	&__wrap {
		font-size: 0;
		margin-bottom: 10px;
	}

	&__about {
		width: 470px;
		display: inline-block;
		vertical-align: top;
		padding-right: 140px;
		box-sizing: border-box;
	}

	&__info {
		width: 285px;
		display: inline-block;
		vertical-align: top;
		padding-right: 90px;
		box-sizing: border-box;
	}

	&__contacts {
		width: 180px;
		display: inline-block;
		vertical-align: top;
	}

	&__social {
		font-size: 0;
	}

	&__social-link {
		vertical-align: top;
		display: inline-block;
		margin-right: 5px;
		width: 38px;
		height: 38px;
		border: 1px solid #a4a4a4;
		border-radius: 3px;
		position: relative;

		&:hover {
			background: #ed4e3b;
			border: 1px solid #ed4e3b;

			.footer__social-img--vk {
				background-position: -32px -19px;
				width: 16px;
				height: 9px;
			}

			.footer__social-img--fb {
				background-position: -49px -49px;
				width: 8px;
				height: 13px;
			}

			.footer__social-img--in {
				background-position: -34px -49px;
				width: 13px;
				height: 13px;
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__social-img {
		position: absolute;
		top: 50%;
		transform: translateY(-50%) translateX(-50%);
		left: 50%;
		background-image: url('../img/sprites/png/png-sprite.png');
		width: 16px;
		height: 9px;

		&--vk {
			background-position: -50px -19px;
			width: 16px;
			height: 9px;
		}

		&--fb {
			background-position: -58px -32px;
			width: 8px;
			height: 13px;
		}

		&--in {
			background-position: -19px -49px;
			width: 13px;
			height: 13px;
		}
	}

	&__menu-title {
		font-size: 14px;
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		color: #1a1c20;
		margin-bottom: 15px;
		display: block;
	}

	&__link {
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		font-size: 14px;
		color: #656668;
		display: inline-block;
		text-decoration: none;

		&:hover {
			color: #ed4e3b;
			text-decoration: underline;
		}
	}

	&__links-item {
		margin-bottom: 20px;
	}

	&__menu-text {
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		font-size: 14px;
		color: #656668;
		line-height: 24px;
	}

	&__phone {
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		font-size: 14px;
		color: #656668;
		margin-bottom: 10px;
	}

	&__mail {
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		font-size: 14px;
		color: #656668;
		margin-bottom: 10px;
		display: block;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
			color: #ed4e3b;
		}
	}

	&__clients-wrap {
		background: url('../img/images/clients.png') no-repeat;
		background-size: 100%;
		background-position: 0 0;
		height: 55px;
		margin-bottom: 35px;
	}

	&__bottom {
		border-top: 1px solid #a4a4a4;
		padding: 25px 0;
		font-size: 0;
	}

	&__left {
		display: inline-block;
		vertical-align: top;
		width: 50%;
		text-align: left;
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		font-size: 14px;
		color: #656668;
	}

	&__right {
		display: inline-block;
		vertical-align: top;
		width: 50%;
		text-align: right;

		.app-star {
			font-family: 'elektra_text_proregular';
			font-weight: 400;
			font-size: 14px;
			color: #656668;
			display: inline-block;
			text-decoration: none;
		}
	}
}

/* RATING */

.rating {
	display: none;
}

/* ENTER-FORM */

.enter-form {
	background: #46a7c8;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 0;
	opacity: 0;
	transition: all 0.3s ease;
	z-index: 0;
	padding: 25px 0;

		.container {
			font-size: 0;
			text-align: right;
		}

		&__reg {
			display: inline-block;
			vertical-align: middle;
			font-family: 'elektra_text_proregular';
			font-weight: 400;
			font-size: 14px;
			color: #ffffff;
			text-decoration: underline;
			margin-right: 30px;
		}

		&__form {
			display: inline-block;
			vertical-align: middle;
			font-size: 14px;
		}

		&__submit {
			border: 1px solid #ffffff;
			border-radius: 3px;
			font-family: 'elektra_text_proregular';
			font-weight: 400;
			color: #ffffff;
			text-align: center;
			padding: 10px 15px;
			background: transparent;

			&:hover {
				background: #4db3d6;
			}
		}

		&__input {
			width: 275px;
			margin-right: 10px;
			padding: 11px 10px;
			border-radius: 3px;
			color: #656668;
		}

		&__forgot {
			padding-top: 20px;
			display: block;
			font-family: 'elektra_text_proregular';
			font-weight: 400;
			color: #ffffff;
			font-size: 14px;
			padding-right: 245px;
		}
}

.triangle {
	position: relative;

		/* Required for Down Triangle */
	&:before, &:after {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-color: transparent;
		border-bottom: 0;
		transform: rotate(180deg);
	}

	/* Stroke */
	&:before {
		top: -16px;
		right: 8px;

		/* If 1px darken stroke slightly */
		border-top-color: #777;
		border-width: 16px;
	}

		/* Fill */
	&:after {
		top: -16px;
		right: 9px;
		border-top-color: #ffffff;
		border-width: 15px;
		height: 16px;
	}
}

.show-form .enter-form {
	height: auto;
	opacity: 1;
	z-index: 3;
}

.show-form .header {
	position: relative;
	top: 124px;
}
.show-form .main {
	position: relative;
	top: 124px;
}

/* MODAL */

.modal {
	position: fixed;
	z-index: 11;
	display: none;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	&__trigger {
		position: absolute;
		background-color: rgba(255, 231, 232, 0.7);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

}

/* MODAL-CATALOG */

.modal-catalog {
	width: 100%;
	&__content {
		min-height: 540px;
		position: relative;
		padding-top: 30px;
	}

	.main__tabs-item.active-tab:after {
		top: 63px;
	}

	// .main__tabs-link {
	// 	border: 1px solid transparent;
	// }

	&__cross {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		width: 50px;
		height: 50px;
		padding: 0;
		cursor: pointer;
		outline: 0;
		background-color: transparent;

		&:hover:after {
			background: #ee393e;
		}

		&:hover:before {
			background: #ee393e;
		}
	}

	&__cross:after, &__cross:before {
		position: absolute;
		top: 29px;
		right: 20px;
		width: 25px;
		height: 2px;
		content: '';
		opacity: .5;
		background: #202226;
	}

	&__cross:before {
		transform: rotate(-45deg);
	}

	&__cross:after {
		transform: rotate(45deg);
	}

	.all-link {
		font-family: 'elektra_text_proregular';
		font-size: 13px;
		color: #656668;
		font-weight: 400;
		position: relative;

		&:hover {
			color: #ed4e3b;
		}

		&:after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: -15px;
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 3.5px 0 3.5px 4px;
			border-color: transparent transparent transparent #656668;
		}
	}
}


/* MODAL-CART */

.modal-cart {
	width: 800px;
	margin: 200px auto 0;
	height: auto;
	background: #ffffff;
	z-index: 5;
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	box-shadow: 0 0 20px 0 rgba(0,0,0,.17);
	padding: 40px 40px 20px 40px;

	&__cross {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		width: 50px;
		height: 50px;
		padding: 0;
		cursor: pointer;
		outline: 0;
		background-color: transparent;

		&:hover:after {
			background: #ee393e;
		}

		&:hover:before {
			background: #ee393e;
		}
	}

	&__cross:after, &__cross:before {
		position: absolute;
		top: 29px;
		right: 20px;
		width: 25px;
		height: 2px;
		content: '';
		opacity: .5;
		background: #202226;
	}

	&__cross:before {
		transform: rotate(-45deg);
	}

	&__cross:after {
		transform: rotate(45deg);
	}

	&__title {
		font-size: 24px;
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		color: #1a1c20;
		text-align: left;
		margin-bottom: 30px;
	}

	&__wrap {
		font-size: 0;
	}

	&__left {
		width: 50%;
		display: inline-block;
		vertical-align: top;
		padding-right: 50px;
	}

	&__right {
		width: 50%;
		display: inline-block;
		vertical-align: top;
		position: relative;
	}

	&__personal-title {
		font-size: 16px;
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		color: #1a1c20;
		text-align: left;
		margin-bottom: 10px;
	}

	&__paymethod-title {
		font-size: 16px;
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		color: #1a1c20;
		text-align: left;
		margin-bottom: 9px;
	}

	&__personal-item {
		margin-bottom: 10px;
		position: relative;

		&.has-error {
			.modal-cart__input--mail {
				border: 1px solid #ed4e3b;
			}
			.modal-cart__validation-error {
				display: block;
			}
		}
	}

	&__input {
		border: 1px solid #aeb0b4;
		width: 100%;
		padding: 10px;
		height: 45px;
		display: block;
		color: #656668;
		font-size: 14px;
	}

	&__personal-info {
		color: #656668;
		padding: 15px 0 25px;
		text-align: left;
		line-height: 18px;
		font-size: 12px;
		font-family: 'elektra_text_proregular';
		font-weight: 400;
	}

	&__submit {
		text-align: left;

		input, button {
			display: inline-block;
			width: 150px;
			height: 45px;
			background: #ed4e3b;
			color: #ffffff;
			border-radius: 3px;
			text-decoration: none;
			border: none;
			margin: 0;
			text-align: center;
			font-size: 14px;

			&:hover {
				background: #fe7c6d;
			}
		}
	}

	&__paymethod {
		display: block;
		margin-bottom: 10px;
		border: 1px solid transparent;
	}

	&__paymethod-item {
		display: block;
		padding: 10px;
		border: 1px solid transparent;
	}

	&__paymethod-item-wrap {
		font-size: 0;
		display: block;
		cursor: pointer;
	}

	&__payment-img {
		display: inline-block;
		vertical-align: middle;
	}

	&__paymethod-name {
		color: #656668;
		font-size: 14px;
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		display: inline-block;
		vertical-align: middle;
		padding-left: 10px;

		&:hover {
			color: #ed4e3b;
		}
	}

	&__validation-error {
		position: absolute;
		background: url('../img/images/validation-error.png');
		background-repeat: no-repeat;
		width: 170px;
		height: 70px;
		top: -3px;
		left: -175px;
		display: none;
	}

	&__paymethod-error {
		position: absolute;
		background: url('../img/images/paymethod-error.png');
		background-repeat: no-repeat;
		width: 210px;
		height: 130px;
		top: 50px;
		right: -150px;
		display: none;
		z-index: 2;
	}
}

.empty-paymethod {
	.modal-cart__paymethod-error {
		display: block;
	}
	.modal-cart__paymethod {
		border: 1px solid #ed4e3b;
	}
}

.paymethod-active {
	border: 1px solid #aeb0b4;
	position: relative;

	&:after {
		position: absolute;
		content: '';
		background-image: url('../img/sprites/png/png-sprite.png');
		background-position: 0px -49px;
		width: 17px;
		height: 17px;
		background-repeat: no-repeat;
		display: inline-block;
		top: 10px;
		right: 5px;
	}

	.modal-cart__paymethod-name {
		color: #ed4e3b;
	}
}

::-webkit-input-placeholder {color:#656668;}
::-moz-placeholder          {color:#656668;}/* Firefox 19+ */
:-moz-placeholder           {color:#656668;}/* Firefox 18- */
:-ms-input-placeholder      {color:#656668;}

.toggle--modal-cart .modal {
	display: block;
}

.toggle--modal-cart .hide {
	display: none;
}

.payment-form {
	width: 640px;
	text-align: center;
}

/* BRANDS */

.brands {
	background: #fdf3c8;
	padding: 15px 15px 20px 15px;
	margin-bottom: 10px;
	height: auto;

	&__title {
		font-size: 14px;
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		color: #1a1c20;
		text-align: left;
		display: block;
	}

	&__options {
		font-size: 0;
		width: 785px;
		height: 63px;
		padding-top: 10px;
		overflow: hidden;
	}

	&__wrap {
		display: inline-block;
		width: 25%;
		box-sizing: border-box;
		padding-right: 35px;
		margin-bottom: 10px;
	}

	&__all-wrap {
		display: none;
	}

	&__all {
		color: #656668;
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		font-size: 14px;
		padding-top: 5px;
		text-decoration: none;
		opacity: 1;
		position: relative;
		padding-left: 15px;

		&--expand {
			&:before {
				content: '';
				height: 2px;
				width: 7px;
				display: block;
				background: #1a1c20;
				transform: rotate(-45deg);
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				position: absolute;
				top: 11px;
				left: 4px;
			}

			&:after {
				content: '';
				height: 2px;
				display: block;
				background: #1a1c20;
				transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				position: absolute;
				top: 11px;
				left: 1px;
				width: 6px;
			}
		}

		&--turn {
			display: none;
			&:before {
				content: '';
				height: 2px;
				width: 6px;
				display: block;
				background: #1a1c20;
				transform: rotate(-45deg);
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				position: absolute;
				top: 11px;
				left: 1px;
				width: 7px;
			}

			&:after {
				content: '';
				height: 2px;
				width: 7px;
				display: block;
				background: #1a1c20;
				transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				position: absolute;
				top: 11px;
				left: 5px;
			}
		}

		&:hover {
			color: #ed4e3b;
		}

	}

	&__checkbox {
		vertical-align: top;
		margin: 0 3px 0 0;
		width: 17px;
		height: 17px;

		& + label {
			cursor: pointer;
		}

		& + label.disabled {
			cursor: not-allowed;
		}

		&:not(checked) {
			position: absolute;
			opacity: 0;
		}

		&:not(checked) + label {
			position: relative; /* будем позиционировать псевдочекбокс относительно label */
			padding: 0 0 0 25px; /* оставляем слева от label место под псевдочекбокс */
		}

		&:not(checked) + label:before {
			content: "";
			position: absolute;
			top: -1px;
			left: 0;
			width: 16px;
			height: 16px;
			border-radius: 2px;
			background: #ffffff;
			border: 1px solid #aeb0b4;
		}

		/* Меняем фон чекбокса, когда он включен. */
		&:checked + label:before {
			background-image: url('../img/images/check.gif');
			background-color: #ffffff;
			background-repeat: no-repeat;
			background-position: 48% 50%;
		}

		// &:focus + label:before {
		// 	box-shadow: 0 0 0 3px rgba(255,255,0,.5);
		// }
	}

	&__name {
		color: #656668;
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		font-size: 14px;

		&.disabled:before {
			opacity: 0.4;
		}
	}
}

/* OPTIONS */

.options {
	// background: #c8f1ff;
	// padding: 15px 15px 20px 15px;
	width: 100%;
	// margin-right: 10px;
	// display: inline-block;
	// vertical-align: top;
	// height: 165px;

	&__wrap {
		font-size: 0;
		box-sizing: border-box;
	}

	&__title {
		font-size: 14px;
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		color: #1a1c20;
		text-align: left;
		margin-bottom: 10px;
		display: block;
	}

	&__item {
		width: 20%;
		display: inline-block;
		// padding-right: 35px;
		box-sizing: border-box;
		vertical-align: top;
		padding: 15px 0;
		background: #c8f1ff;
		min-height: 165px;
		&:nth-child(2) {
			padding-left: 15px;
		}
		&:nth-child(5) {
			margin-right: 2%;
			width: 18%;
		}
	}

	&__checkbox {
		vertical-align: top;
		margin: 0 3px 0 0;
		width: 17px;
		height: 17px;

		& + label {
			cursor: pointer;
		}

		& + label.disabled {
			cursor: not-allowed;
		}

		&:not(checked) {
			position: absolute;
			opacity: 0;
		}

		&:not(checked) + label {
			position: relative; /* будем позиционировать псевдочекбокс относительно label */
			padding: 0 0 0 25px; /* оставляем слева от label место под псевдочекбокс */
		}

		&:not(checked) + label:before {
			content: "";
			position: absolute;
			top: -1px;
			left: 0;
			width: 16px;
			height: 16px;
			border-radius: 2px;
			background: #ffffff;
			border: 1px solid #aeb0b4;
		}

		/* Меняем фон чекбокса, когда он включен. */
		&:checked + label:before {
			background-image: url('../img/images/check.gif');
			background-color: #ffffff;
			background-repeat: no-repeat;
			background-position: 48% 50%;
		}

		// &:focus + label:before {
		// 	box-shadow: 0 0 0 3px rgba(255,255,0,.5);
		// }
	}

	&__name {
		color: #656668;
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		font-size: 14px;

		&.disabled:before {
			opacity: 0.4;
		}
	}

	&__item-wrap {
		margin-bottom: 10px;
	}
}

/* PRICE */

.price {
	background: #ffddde;
	display: inline-block;
	vertical-align: top;
	width: 20%;
	padding: 15px 15px 20px 15px;
	height: 165px;
	border: 0;
	margin: 0;
	font-size: 14px;

	&__title {
		font-size: 14px;
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		color: #1a1c20;
		text-align: left;
		margin-bottom: 10px;
		display: block;
	}

	&__input {
		border: 1px solid #aeb0b4;
		border-radius: 2px;
		padding: 8px;
		margin-bottom: 5px;
		width: 100%;
		font-size: 14px;
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		color: #656668;

		&:nth-child(3) {
			margin-bottom: 30px;
		}
	}

	&__ruler {
		font-size: 12px;
		position: absolute;
		top: -21px;
		color: #656668;
		&--left {
			left: 0;
		}

		&--right {
			right: 0;
		}
	}

	.input-small {
		border: 1px solid #aeb0b4;
		border-radius: 2px;
		padding: 8px;
		margin-bottom: 5px;
		width: 100%;
		font-size: 14px;
		color: #656668;
	}

	.ui-widget-content {
		border: 0;
	}

	.ui-slider-horizontal {
		height: 3px;
	}

	.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-state-focus, .ui-state-hover {
		border: 0;
		color: #2e6e9e;
		border-radius: 50%;
		background: #FFF;
		cursor: default;
		box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.38);
	}
	.ui-slider-horizontal .ui-slider-handle {
		top: -.4em;
		margin-left: -.6em;
	}
	.ui-slider .ui-slider-handle {
		position: absolute;
		z-index: 2;
		width: 15px;
		height: 15px;
		cursor: default;
	}

	.mse2_number_inputs {
		margin-bottom: 20px;
		label {
			font-size: 0;
		}
	}
}

.filter-btn {
	height: 0;
	font-size: 0;
	text-align: center;
	transition: all 0.3s ease;
	opacity: 0;

	&__wrap {
		width: 150px;
		height: 40px;
		margin-right: 15px;
		text-align: center;
		display: inline-block;
	}

	&__clear {
		padding: 15px;
		font-size: 14px;
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		color: #656668;
		border-radius: 3px;
		border: 1px solid #aeb0b4;
		text-decoration: none;
		display: block;
		background: transparent;

		&:hover {
			background: #ecf9fd;
		}
	}

	&__show {
		padding: 15px;
		font-size: 14px;
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		color: #ffffff;
		border-radius: 3px;
		border: 1px solid #ed4e3b;
		text-decoration: none;
		background: #ed4e3b;
		display: block;

		&:hover {
			background: #ed4e3b;
		}
	}
}

.catalog-card {
	border: 1px solid #aeb0b4;
	padding: 30px;
	font-size: 0;
	margin-bottom: 50px;
	z-index: 3;
	// position: relative;

	.new-price {
		margin-bottom: 25px;
	}

	&__text {
		display: inline-block;
		vertical-align: top;
		width: 528px;
		padding-right: 60px;
	}

	&__btn-wrap {
		text-align: left;
		margin-bottom: 25px;
	}

	&__btn {
		width: 145px;
		height: 45px;
		background: #ed4e3b;
		border-radius: 3px;
		color: #fff;
		text-align: center;
		display: inline-block;
		font-size: 14px;
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		text-decoration: none;
	}

	&__photos {
		display: inline-block;
		vertical-align: top;
		width: 350px;
	}

	&__main-photo {
		height: 350px;
		width: 350px;
		overflow: hidden;
		margin-bottom: 10px;

		img {
			display: block;
		}
	}

	&__small-photos {
		.small-photo {
			float: left;
			margin-right: 10px;
			width: 80px;
			margin-bottom: 10px;

			&:nth-child(4n+4) {
				margin-right: 0;
			}
		}
	}

	.show-desc {
		height: 100%;
		opacity: 1;
		display: block;
	}
}

.card {
	&__title {
		font-size: 14px;
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		color: #1a1c20;
		margin-bottom: 15px;
		display: block;
	}

	&__desc-wrap {
		p {
			font-size: 14px;
			font-family: 'elektra_text_proregular';
			font-weight: 400;
			color: #656668;
			margin-bottom: 20px;
			line-height: 24px;

			&:last-child {
				margin-bottom: 15px;
			}
		}

		ul {
			margin-bottom: 20px;
			padding-left: 20px;
		}
	}

	&__functions-list {
		margin-bottom: 20px;
		font-size: 14px;

		ul {
			list-style: none;
		}

		li {
			position: relative;
			padding-left: 12px;
			font-size: 14px;
			font-family: 'elektra_text_proregular';
			font-weight: 400;
			color: #656668;
			margin-bottom: 10px;

			&:before {
				position: absolute;
				top: 4px;
				left: 0px;
				width: 6px;
				content: "";
				height: 6px;
				background: #46a7c8;
				border-radius: 50%;
			}
		}

		p {
			margin-bottom: 10px;
		}
	}

	&__desc {
    margin-bottom: 20px;
    height: 0;
    opacity: 0;
    display: none;

    #product-detail-text {
    	font-size: 14px;
    }
	}

	&__show-wrap {
		text-align: left;
		margin-bottom: 20px;
	}

	&__show-btn {
		border: 1px solid #a4a4a4;
		border-radius: 3px;
		padding: 15px;
		font-size: 14px;
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		color: #656668;
		text-decoration: none;
		display: inline-block;

		&:hover {
			background: #ecf9fd;
		}
	}

	&__turn-wrap {
		width: 95px;
		height: 40px;
		text-align: center;
	}

	&__turn-btn {
		border: 1px solid #a4a4a4;
		border-radius: 3px;
		padding: 15px;
		font-size: 14px;
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		color: #656668;
		text-decoration: none;
		display: block;

		&:hover {
			background: #ecf9fd;
		}
	}

	&__table {
		margin-bottom: 25px;
		font-size: 14px;
	}

	&__table-name {
		font-size: 14px;
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		color: #656668;
		background: url('../img/images/dot.gif') center 65% repeat-x;
		min-width: 188px;

		.table-bg {
			background: #fff;
			display: inline-block;
			padding: 1px;
			white-space: nowrap;
		}
	}

	&__table-desc {
		font-size: 14px;
		font-family: 'elektra_text_proregular';
		font-weight: 400;
		color: #656668;

		a {
			font-size: 14px;
			font-family: 'elektra_text_proregular';
			font-weight: 400;
			color: #656668;
			text-decoration: none;

			&:hover {
				color: #ed4e3b;
			}
		}

		.table-bg {
			background: #fff;
			display: inline-block;
			margin-top: 1px;
			white-space: nowrap;
		}
	}
}

/* RATING */

.productRate {
	display: inline-block;
	background-image: url('../img/images/empty-star-icon.png');
	width: 113px;
	height: 15px;
	margin-bottom: 15px;
}
.productRate div {
	background-image: url('../img/images/full-star-icon.png');
	width: 0;
	height: 100%;
}

/* FORGOT PASSWORD */

.forgot-message {
	color: #656668;
	font-size: 14px;
	font-family: 'elektra_text_proregular';
	font-weight: 400;
	text-align: left;
	max-width: 560px;
	margin-bottom: 45px;
}

.loginFPEmail, .loginUsername, .loginPassword, .loginPhone {
	border: 1px solid #aeb0b4;
	background: #ecf9fd;
	color: #656668;
	padding: 15px;
	margin-bottom: 20px;
	display: block;
	width: 270px;
	border-radius: 3px;
}

.loginFPButton {
	display: block;
	width: 150px;
	height: 45px;

	input[type="submit"] {
		display: block;
		background: #ed4e3b;
		color: #ffffff;
		border-radius: 3px;
		text-decoration: none;
		line-height: 45px;
		text-align: center;
		width: 100%;
		&:hover {
			background: #fe7c6d;
		}
	}
}

p.error {
	font-family: 'elektra_text_proregular';
	font-weight: 400;
	font-size: 14px;
	color: #656668;
	margin-bottom: 40px;
}

.loginLoginButton {
	font-size: 0;
	padding: 10px 0;
}

.input-box {
	color: #656668;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
}

.reg-btn {
	display: inline-block;
	vertical-align: top;
	background: #ed4e3b;
	color: #ffffff;
	border-radius: 3px;
	text-decoration: none;
	line-height: 45px;
	text-align: center;
	font-size: 14px;
	padding: 0 20px;
	margin-right: 20px;
	&:hover {
		background: #fe7c6d;
	}
}

.account__change-btn {
	display: inline-block;
	vertical-align: middle;
	line-height: 45px;
	color: #656668;
	font-size: 14px;
	font-weight: 400;
}

.input-box {
	&__checkbox {
			vertical-align: top;
			margin: 0 3px 0 0;
			width: 17px;
			height: 17px;

			& + label {
				cursor: pointer;
			}

			&:not(checked) {
				position: absolute;
				opacity: 0;
			}

			&:not(checked) + label {
				position: relative; /* будем позиционировать псевдочекбокс относительно label */
				padding: 0 0 0 25px; /* оставляем слева от label место под псевдочекбокс */
			}

			&:not(checked) + label:before {
				content: "";
				position: absolute;
				top: -1px;
				left: 0;
				width: 16px;
				height: 16px;
				border-radius: 2px;
				background: #ffffff;
				border: 1px solid #aeb0b4;
			}

			/* Меняем фон чекбокса, когда он включен. */
			&:checked + label:before {
				background-image: url('../img/images/check.gif');
				background-color: #ffffff;
				background-repeat: no-repeat;
				background-position: 48% 50%;
			}
		}
}

.input-box.newsletter {
	margin-bottom: 20px;
}

.enter-btn {
	display: inline-block;
	width: 80px;
	vertical-align: top;
	background: #ed4e3b;
	color: #ffffff;
	border-radius: 3px;
	text-decoration: none;
	line-height: 45px;
	text-align: center;
	font-size: 14px;
	&:hover {
		background: #fe7c6d;
	}
}

.buttons-set {
	display: inline-block;
	width: 190px;
	vertical-align: top;
	padding: 0 20px 0 25px;
}

.registration-btn {
	display: block;
	text-decoration: underline;
	color: #656668;
	font-weight: 400;
	font-size: 14px;
	text-align: left;
	margin-bottom: 10px;
}

.forgot-btn {
	display: block;
	text-decoration: underline;
	color: #656668;
	font-weight: 400;
	font-size: 14px;
	text-align: left;
}

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-touch-action: none;
	touch-action: none;
-ms-user-select: none;
-moz-user-select: none;
	user-select: none;
-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-target {
	position: relative;
	direction: ltr;
}
.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1; /* Fix 401 */
}
.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
}
.noUi-handle {
	position: relative;
	z-index: 1;
}
.noUi-stacking .noUi-handle {
/* This class is applied to the lower origin when
	 its values is > 50%. */
	z-index: 10;
}
.noUi-state-tap .noUi-origin {
-webkit-transition: left 0.3s, top 0.3s;
	transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
	cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
	height: 3px;
}
.noUi-horizontal .noUi-handle {
	width: 15px;
	height: 15px;
	left: -8px;
	top: -6px;
}
.noUi-vertical {
	width: 18px;
}
.noUi-vertical .noUi-handle {
	width: 15px;
	height: 15px;
	left: -8px;
	top: -17px;
}

/* Styling;
 */
.noUi-background {
	background: #ffffff;
}
.noUi-connect {
	background: #66b9d5;
-webkit-transition: background 450ms;
	transition: background 450ms;
}
.noUi-origin {
	border-radius: 2px;
}
.noUi-target {
	border-radius: 0;
}
.noUi-target.noUi-connect {
	box-shadow: inset 0 0 3px rgba(51,51,51,0.45), 0 3px 6px -5px #BBB;
}

/* Handles and cursors;
 */
.noUi-draggable {
	cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
	cursor: n-resize;
}
.noUi-handle {
	border-radius: 50%;
	background: #FFF;
	cursor: default;
	box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.38);
}
.noUi-active {
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
	width: 14px;
	height: 1px;
	left: 6px;
	top: 14px;
}
.noUi-vertical .noUi-handle:after {
	top: 17px;
}

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
	background: #B8B8B8;
}
[disabled].noUi-origin,
[disabled] .noUi-handle {
	cursor: not-allowed;
}

.example-val {
	font: 400 12px Arial;
	color: #888;
	display: block;
	margin: 15px 0;
	&:before {
    content: "Value: ";
    font: 700 12px Arial;
	}
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
	-webkit-animation-duration: 1000ms;
	animation-duration: 1000ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
	z-index: 0;
}
.owl-carousel .owl-animated-out {
	z-index: 1;
}
.owl-carousel .fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}

/*
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
	display: none;
	width: 760px;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}
.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.owl-carousel.owl-loaded {
	display: block;
	margin: 0 auto;
}
.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}
.owl-carousel.owl-hidden {
	opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
	display: none;
}
.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.owl-carousel.owl-text-select-on .owl-item {
	-webkit-user-select: auto;
	-moz-user-select: auto;
	-ms-user-select: auto;
	user-select: auto;
}
.owl-carousel .owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: -o-grab;
	cursor: -ms-grab;
	cursor: grab;
}
.owl-carousel.owl-rtl {
	direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
	float: right;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	-webkit-transition: opacity 400ms ease;
	-moz-transition: opacity 400ms ease;
	-ms-transition: opacity 400ms ease;
	-o-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}

/*
 *  Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-controls {
}
.owl-theme .owl-controls .owl-nav .disabled {
	opacity: 0.5;
	cursor: default;
}

/* My styles */

.catalog-carousel {
	&__item {
    text-align: center;
	}

	&__title {
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		font-size: 14px;
		color: #1a1c20;
		margin-bottom: 25px;
	}

	&__img {
		display: block;
		margin: 0 auto 10px;
	}

	.productRate {
		margin-bottom: 8px;
	}

	&__link {
		font-size: 14px;
		font-family: "elektra_text_proregular";
		font-weight: 400;
		color: #36849e;
		margin-bottom: 5px;
		display: block;

		&:hover {
			color: #ed4e3b;
		}
	}

	.new-price {
		font-size: 18px;
		font-family: "elektra_text_proregular";
		font-weith: 400;
		color: #1a1c20;
	}
}
.owl-nav {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.owl-controls {
	position: absolute;
	width: 100%;
	height: 25px;
	top: 0;
	left: 0;
}
.owl-theme .owl-controls .owl-prev {
	position: absolute;
	left: -97px;
	z-index: 2;
	width: 25px;
	height: 25px;
	text-indent: -9999px;
	outline: 0;
	cursor: pointer;
	display: inline-block;
	top: 110px;
}
.owl-prev {
	top: -50%;
	transform: translateY(-50%);

	&:before {
		transform: rotate(45deg);
		position: absolute;
		top: 0px;
		left: 12px;
		width: 2px;
		height: 12px;
		content: ' ';
		background-color: #202226;
	}

	&:hover:before {
		background-color: #ed5340;
	}

	&:after {
		transform: rotate(-45deg);
		position: absolute;
		top: 8px;
		left: 12px;
		width: 2px;
		height: 12px;
		content: ' ';
		background-color: #202226;
	}

	&:hover:after {
		background-color: #ed5340;
	}
}
.owl-next {
	position: absolute;
	right: -97px;
	top: 110px;
	z-index: 2;
	width: 25px;
	height: 25px;
	text-indent: -9999px;
	outline: 0;
	cursor: pointer;
	display: inline-block;
	transform: translateY(-50%);

	&:before {
		transform: rotate(-45deg);
		position: absolute;
		top: 0px;
		left: 12px;
		width: 2px;
		height: 12px;
		content: ' ';
		background-color: #202226;
	}

	&:hover:before {
		background-color: #ed5340;
	}

	&:after {
		transform: rotate(45deg);
		position: absolute;
		top: 8px;
		left: 12px;
		width: 2px;
		height: 12px;
		content: ' ';
		background-color: #202226;
	}

	&:hover:after {
		background-color: #ed5340;
	}
}

.owl-nav {
	position: relative;
}
.owl-dots {
	float: right;
	margin-right: -97px;
	margin-top: -30px
}
.owl-theme .owl-dots .owl-dot {
	display: inline-block;
	zoom: 1;
	*display: inline;
}
.owl-theme .owl-dots .owl-dot span {
	width: 10px;
	height: 10px;
	margin: 0 7px;
	background: #ffddde;
	border: 1px solid #a6a6a6;
	display: block;
	transition: opacity 200ms ease;
	border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #dd1523;
	border: 1px solid #dd1523;
}


/* BASKET */

.table {
	width: 100%;

	th {
		color: #1a1c20;
		font-size: 14px;
		// font-family: 'elektra_medium_probold';
		font-weight: 700;
		text-align: left;
		padding-bottom: 15px;

		&.remove {
			text-align: right;
		}
	}

	.table-card {
		border: 1px solid #aeb0b4;
		margin-bottom: 20px;
	}

}

.table-remove {
	position: relative;
	width: 25px;
	height: 25px;
	padding: 0;
	cursor: pointer;
	outline: 0;
	background-color: transparent;
	margin-top: 2px;

	&:hover:after {
		background: #ee393e;
	}

	&:hover:before {
		background: #ee393e;
	}
}

.table-remove:after, .table-remove:before {
	position: absolute;
	top: 12px;
	right: 0;
	width: 25px;
	height: 2px;
	content: '';
	opacity: .5;
	background: #202226;
}

.table-remove:before {
	transform: rotate(-45deg);
}

.table-remove:after {
	transform: rotate(45deg);
}

.cart-btn {
	text-align: center;
	&__link {
		display: inline-block;
		width: 150px;
		height: 45px;
		background: #ed4e3b;
		color: #ffffff;
		border-radius: 3px;
		text-decoration: none;
		line-height: 45px;
		text-align: center;

		&:hover {
			background: #fe7c6d;
		}
	}
}

:focus::-webkit-input-placeholder {color: transparent}
:focus::-moz-placeholder          {color: transparent}
:focus:-moz-placeholder           {color: transparent}
:focus:-ms-input-placeholder      {color: transparent}

/* Perspective effect */

html, body, .perspective {
	width: 100%;
	height: 100%;
}

.perspective {
	background: #aaa;
	position: relative;
}

.container-perspective {
	background: #fff;
	min-height: 100%;
	position: relative;
	outline: 1px solid rgba(0,0,0,0);
	z-index: 10;
	-webkit-transform: translateZ(0) translateX(0) rotateY(0deg); /* reset transforms (Chrome bug) */
	transform: translateZ(0) translateX(0) rotateY(0deg);
}

.container-perspective::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 0px;
	opacity: 0;
	background: rgba(0,0,0,0.2);
	/* the transition delay of the height needs to be synced with the container transition time */
	-webkit-transition: opacity 0.4s, height 0s 0.4s;
	transition: opacity 0.4s, height 0s 0.4s;
}

.wrapper {
	position: relative;
}

.component {
	margin: 0 auto;
	width: 60%;
	text-align: justify;
	font-size: 1.5em;
}

/* Modal view */
.perspective.modalview {
	position: fixed;
	-webkit-perspective: 1500px;
	perspective: 1500px;
}

.modalview .container-perspective {
	position: absolute;
	overflow: hidden;
	width: 100%;
	height: 100%;
	cursor: pointer;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.modalview .wrapper {
	-webkit-transform: translateZ(-1px); /* solves a rendering bug in Chrome on Windows */
}

.animate .container-perspective::after {
	opacity: 1;
	height: 101%;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
}

/* Outer Nav */
.outer-nav {
	position: absolute;
	height: auto;
	box-shadow: 0px 7px 7px 0px rgba(0,0,0,0.17);
	background: #fff;
	padding: 0 30px;
	height: 0;

	.main__tabs-link {
		padding: 0;
	}

	.modal-catalog .main__tabs-item.active-tab:after {
		top: 59px;
	}

	.main__catalog-wrap {
		padding: 0;
	}
}

.outer-nav.vertical {
	top: 20%;
	-webkit-transform: translateY(-20%);
	transform: translateY(-20%);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.outer-nav.horizontal {
	left: 50%;
	width: 75%;
	max-width: 1000px;
	text-align: center;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.outer-nav.left {
	left: 25%;
}

.outer-nav.right {
	right: 25%;
}

.outer-nav.top {
	top: 25%;
}

.outer-nav.bottom {
	bottom: 25%;
}

// .outer-nav a {
// 	display: inline-block;
// 	white-space: nowrap;
// 	font-weight: 300;
// 	margin: 0 0 30px 0;
// 	color: #fff;
// 	-webkit-transition: color 0.3s;
// 	transition: color 0.3s;
// 	-webkit-transform-style: preserve-3d;
// 	transform-style: preserve-3d;
// }

// .outer-nav a:hover {
// 	color: #ffdccd;
// }

// .outer-nav.vertical a {
// 	display: block;
// }

// .outer-nav.horizontal a {
// 	margin: 15px 20px;
// }

// .outer-nav a::before {
// 	display: inline-block;
// 	font-family: 'typicons';
// 	speak: none;
// 	font-style: normal;
// 	font-weight: normal;
// 	font-variant: normal;
// 	text-transform: none;
// 	line-height: 1;
// 	margin-right: 10px;
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// }

// .icon-news::before { content: "\e600" }
// .icon-home::before { content: "\e601" }
// .icon-image::before { content: "\e602" }
// .icon-upload::before { content: "\e603" }
// .icon-star::before { content: "\e604" }
// .icon-mail::before { content: "\e605" }
// .icon-lock::before { content: "\e606" }
// .icon-chart-pie::before { content: "\e607" }

/* Individual Effects */

/* Effect airbnb */
.effect-airbnb {
	background: #b8b6b4;
}

.effect-airbnb .container-perspective {
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

.effect-airbnb.animate .container-perspective {
	-webkit-transform: translateZ(-1500px) translateX(100%) rotateY(-45deg);
	transform: translateZ(-1500px) translateX(100%) rotateY(-45deg);
}

.no-csstransforms3d .effect-airbnb.animate .container-perspective {
	left: 75%;
}

.effect-airbnb .outer-nav a {
	opacity: 0;
	-webkit-transform: translateX(-150px);
	transform: translateX(-150px);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

.effect-airbnb.animate .outer-nav a {
	opacity: 1;
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.effect-airbnb.animate .outer-nav a:nth-child(2) {
	-webkit-transition-delay: 0.04s;
	transition-delay: 0.04s;
}

.effect-airbnb.animate .outer-nav a:nth-child(3) {
	-webkit-transition-delay: 0.08s;
	transition-delay: 0.08s;
}

.effect-airbnb.animate .outer-nav a:nth-child(4) {
	-webkit-transition-delay: 0.12s;
	transition-delay: 0.12s;
}

.effect-airbnb.animate .outer-nav a:nth-child(5) {
	-webkit-transition-delay: 0.16s;
	transition-delay: 0.16s;
}

.effect-airbnb.animate .outer-nav a:nth-child(6) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.effect-airbnb.animate .outer-nav a:nth-child(7) {
	-webkit-transition-delay: 0.24s;
	transition-delay: 0.24s;
}

/* Effect Move Left */
.effect-moveleft {
	background: #f4f3f1;
}

.effect-moveleft .container-perspective {
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

.effect-moveleft .container-perspective::after {
	background: rgba(255,255,255,0.6);
}

.effect-moveleft.animate .container-perspective {
	-webkit-transform: translateX(-50%) rotateY(45deg) translateZ(-50px);
	transform: translateX(-50%) rotateY(45deg) translateZ(-50px);
}

.no-csstransforms3d .effect-moveleft.animate .container-perspective {
	left: -75%;
}

.effect-moveleft .outer-nav a {
	color: #e86a32;
	opacity: 0;
	-webkit-transform: translateX(100px) translateZ(-1000px);
	transform: translateX(100px) translateZ(-1000px);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

.effect-moveleft .outer-nav a:hover {
	color: #333;
}

.effect-moveleft.animate .outer-nav a {
	opacity: 1;
	-webkit-transform: translateX(0) translateZ(0);
	transform: translateX(0) translateZ(0);
}

.effect-moveleft.animate .outer-nav a:nth-child(2) {
	-webkit-transition-delay: 0.04s;
	transition-delay: 0.04s;
}

.effect-moveleft.animate .outer-nav a:nth-child(3) {
	-webkit-transition-delay: 0.08s;
	transition-delay: 0.08s;
}

.effect-moveleft.animate .outer-nav a:nth-child(4) {
	-webkit-transition-delay: 0.12s;
	transition-delay: 0.12s;
}

.effect-moveleft.animate .outer-nav a:nth-child(5) {
	-webkit-transition-delay: 0.16s;
	transition-delay: 0.16s;
}

.effect-moveleft.animate .outer-nav a:nth-child(6) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.effect-moveleft.animate .outer-nav a:nth-child(7) {
	-webkit-transition-delay: 0.24s;
	transition-delay: 0.24s;
}

/* Effect Rotate Left */
.effect-rotateleft {
	background: #e96e4f;
}

.effect-rotateleft .container-perspective {
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform-origin: 0% 50%;
	transform-origin: 0% 50%;
}

.effect-rotateleft .container-perspective::after {
	background: rgba(255,255,255,0.6);
}

.effect-rotateleft.animate .container-perspective {
	-webkit-transform: translateZ(-1800px) translateX(-50%) rotateY(45deg);
	transform: translateZ(-1800px) translateX(-50%) rotateY(45deg);
}

.no-csstransforms3d .effect-rotateleft.animate .container-perspective {
	left: -75%;
}

.effect-rotateleft .outer-nav a {
	opacity: 0;
	-webkit-transform: translateX(350px) translateZ(-1000px);
	transform: translateX(350px) translateZ(-1000px);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

.effect-rotateleft.animate .outer-nav a {
	opacity: 1;
	-webkit-transform: translateX(0) translateZ(0);
	transform: translateX(0) translateZ(0);
}

.effect-rotateleft.animate .outer-nav a:nth-child(2) {
	-webkit-transition-delay: 0.04s;
	transition-delay: 0.04s;
}

.effect-rotateleft.animate .outer-nav a:nth-child(3) {
	-webkit-transition-delay: 0.08s;
	transition-delay: 0.08s;
}

.effect-rotateleft.animate .outer-nav a:nth-child(4) {
	-webkit-transition-delay: 0.08s;
	transition-delay: 0.08s;
}

.effect-rotateleft.animate .outer-nav a:nth-child(5) {
	-webkit-transition-delay: 0.16s;
	transition-delay: 0.16s;
}

.effect-rotateleft.animate .outer-nav a:nth-child(6) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.effect-rotateleft.animate .outer-nav a:nth-child(7) {
	-webkit-transition-delay: 0.24s;
	transition-delay: 0.24s;
}

/* Effect Move Down */
.effect-movedown {
	background-color: rgba(255, 231, 232, 0.7);
}

.effect-movedown .container-perspective {
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

.effect-movedown .container-perspective::after {
	background: rgba(255, 231, 232, 0.7);
}

.effect-movedown.animate .container-perspective {
	-webkit-transform: translateY(100%) translateZ(-1500px);
	transform: translateY(100%) translateZ(-1500px);
}

.no-csstransforms3d .effect-movedown.animate .container-perspective {
	top: 75%;
}

// .effect-movedown .outer-nav a {
// 	opacity: 0;
// 	-webkit-transform: translateY(-20px);
// 	transform: translateY(-20px);
// 	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
// 	transition: transform 0.4s, opacity 0.4s;
// }

.effect-movedown .outer-nav .modal-catalog {
	opacity: 0;
	-webkit-transform: translateY(-20px);
	transform: translateY(-20px);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.2s;
}

// .effect-movedown .outer-nav a:hover {
// 	color: #ed8151;
// }
.effect-movedown.animate .outer-nav {
	height: auto;
}

.effect-movedown.animate .outer-nav .modal-catalog {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

// .effect-movedown .outer-nav ul {
// 	opacity: 1;
// 	-webkit-transform: translateY(0);
// 	transform: translateY(0);
// }
.effect-movedown.animate .outer-nav a:nth-child(2) {
	-webkit-transition-delay: 0.04s;
	transition-delay: 0.04s;
}

.effect-movedown.animate .outer-nav a:nth-child(3) {
	-webkit-transition-delay: 0.08s;
	transition-delay: 0.08s;
}

.effect-movedown.animate .outer-nav a:nth-child(4) {
	-webkit-transition-delay: 0.12s;
	transition-delay: 0.12s;
}

.effect-movedown.animate .outer-nav a:nth-child(5) {
	-webkit-transition-delay: 0.16s;
	transition-delay: 0.16s;
}

.effect-movedown.animate .outer-nav a:nth-child(6) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.effect-movedown.animate .outer-nav a:nth-child(7) {
	-webkit-transition-delay: 0.24s;
	transition-delay: 0.24s;
}

/* Effect Rotate Top */
.effect-rotatetop {
	background: #edcdbb;
}

.effect-rotatetop .container-perspective {
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

.effect-rotatetop .container-perspective::after {
	background: rgba(94,59,43,0.6);
}

.effect-rotatetop.animate .container-perspective {
	-webkit-transform: translateZ(-1500px) translateY(-50%) rotateX(-45deg);
	transform: translateZ(-1500px) translateY(-50%) rotateX(-45deg);
}

.no-csstransforms3d .effect-rotatetop.animate .container-perspective {
	top: -75%;
}

.effect-rotatetop .outer-nav a {
	opacity: 0;
	color: #ed8151;
	-webkit-transform: translateY(200px) translateZ(-1000px);
	transform: translateY(200px) translateZ(-1000px);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

.effect-rotatetop .outer-nav a:hover {
	color: #777;
}

.effect-rotatetop.animate .outer-nav a {
	opacity: 1;
	-webkit-transform: translateY(0) translateZ(0);
	transform: translateY(0) translateZ(0);
}

.effect-rotatetop.animate .outer-nav a:nth-child(2) {
	-webkit-transition-delay: 0.04s;
	transition-delay: 0.04s;
}

.effect-rotatetop.animate .outer-nav a:nth-child(3) {
	-webkit-transition-delay: 0.08s;
	transition-delay: 0.08s;
}

.effect-rotatetop.animate .outer-nav a:nth-child(4) {
	-webkit-transition-delay: 0.12s;
	transition-delay: 0.12s;
}

.effect-rotatetop.animate .outer-nav a:nth-child(5) {
	-webkit-transition-delay: 0.16s;
	transition-delay: 0.16s;
}

.effect-rotatetop.animate .outer-nav a:nth-child(6) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.effect-rotatetop.animate .outer-nav a:nth-child(7) {
	-webkit-transition-delay: 0.24s;
	transition-delay: 0.24s;
}

/* Effect Lay Down */
.effect-laydown {
	background: #b8b6b4;
}

.effect-laydown .container-perspective {
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform-origin: 50% 150%;
	transform-origin: 50% 150%;
}

.effect-laydown.animate .container-perspective {
	-webkit-transform: translateZ(-1500px) rotateX(80deg);
	transform: translateZ(-1500px) rotateX(80deg);
}

.no-csstransforms3d .effect-laydown.animate .container-perspective {
	top: 75%;
}

.effect-laydown .outer-nav a {
	opacity: 0;
	-webkit-transform: translateY(-200px) translateZ(-1000px);
	transform: translateY(-200px) translateZ(-1000px);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

.effect-laydown.animate .outer-nav a {
	opacity: 1;
	-webkit-transform: translateY(0) translateZ(0);
	transform: translateY(0) translateZ(0);
}

.effect-laydown.animate .outer-nav a:nth-child(7){
	-webkit-transition-delay: 0.0s;
	transition-delay: 0.0s;
}

.effect-laydown.animate .outer-nav a:nth-child(6) {
	-webkit-transition-delay: 0.04s;
	transition-delay: 0.04s;
}

.effect-laydown.animate .outer-nav a:nth-child(5) {
	-webkit-transition-delay: 0.08s;
	transition-delay: 0.08s;
}

.effect-laydown.animate .outer-nav a:nth-child(4) {
	-webkit-transition-delay: 0.12s;
	transition-delay: 0.12s;
}

.effect-laydown.animate .outer-nav a:nth-child(3) {
	-webkit-transition-delay: 0.16s;
	transition-delay: 0.16s;
}

.effect-laydown.animate .outer-nav a:nth-child(2) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.effect-laydown.animate .outer-nav a:first-child {
	-webkit-transition-delay: 0.24s;
	transition-delay: 0.24s;
}

/* Media Queries */
@media screen and (max-width: 77em) {

	.outer-nav.top {
		top: 15%;
	}

	.outer-nav.bottom {
		bottom: 15%;
	}
}

@media screen and (max-width: 36.625em), screen and (max-height: 41.75em) {

	// .outer-nav.top {
	// 	top: 5%;
	// }

	// .outer-nav.bottom {
	// 	bottom: 5%;
	// }

	// .outer-nav.horizontal {
	// 	font-size: 1.7em;
	// 	width: 6.2em;
	// }

	// .outer-nav.horizontal a {
	// 	display: block;
	// 	text-align: left;
	// }

	/* Special Case */
	.effect-rotatetop .outer-nav.horizontal {
		width: 95%;
		bottom: auto;
		top: 50%;
	}

	.effect-rotatetop .outer-nav.horizontal a {
		display: inline-block;
	}

}

@media screen and (max-width: 31em), screen and (max-height: 36.2em) {
	.outer-nav.horizontal,
	.outer-nav.vertical {
		font-size: 1.2em;
		width: 6.8em;
	}

	.outer-nav.right {
		right: auto;
		left: 50%;
	}
}

@media screen and (max-height: 31.6em) {
	.outer-nav a {
		margin-bottom: 20px;
	}
}

/* ERROR */

.error {
	color: #ed4e3b;
	display: block;
}

.error-block {
	font-size: 0;
	padding-top: 45px;
}

.error-img {
	width: 160px;
	display: inline-block;
}

.error-404 {
	width: 370px;
	color: #c9c9c9;
	font-size: 200px;
	font-weight: 700;
	padding-left: 25px;
	vertical-align: baseline;
	margin-bottom: 35px;
	line-height: 1;
}

.error-message {
	font-size: 18px;
	color: #656668;
	font-family: 'elektra_text_proregular';
	font-weight: 400;
	text-align: left;
	max-width: 425px;
}

/* WELOCOME */

.welcome {
	font-size: 0;

	&__img {
		display: inline-block;
		vertical-align: top;
		width: 170px;
		&.success {
			width: 135px;
		}
		&.fail {
			width: 145px;
		}

		img {
			display: block;
		}
	}

	&__text {
		padding-top: 20px;
		display: inline-block;
		vertical-align: top;
		font-size: 48px;
		color: #c9c9c9;
		font-weight: 700;
		width: 770px;
		&.success {
			width: 385px;
			padding-top: 10px;
		}
		&.fail {
			width: 375px;
		}
	}

	&__advantages {
		padding-top: 40px;
		text-align: left;
	}

	&__advantages-title {
		font-size: 18px;
		color: #656668;
		font-weight: 400;
		margin-bottom: 30px;
		display: block;
	}

	&__list {
		padding-left: 20px;
	}

	&__list-item {
		font-size: 18px;
		color: #656668;
		font-weight: 400;
		margin-bottom: 5px;
	}

	&.success {
		width: 520px;
		margin-bottom: 45px;
	}

	&.fail {
		margin-bottom: 45px;
	}
}

/* ACCOUNT */

.account {
	&__tabs {
		float: left;
		list-style: none;
		width: 240px;
	}
	&__tabs-item {
		border: 1px solid #fff;
		color: #656668;
		font-size: 14px;
		font-weight: 400;
		text-align: left;
		padding: 20px;
		cursor: pointer;
		&.active {
			border: 1px solid #aeb0b4;
			color: #ed4e3b;
			position: relative;

			&:after {
				position: absolute;
				content: "";
				height: 57px;
				width: 2px;
				background: #ffffff;
				right: -2px;
				top: 0;
			}
		}
	}

	&__info-wrap {
		border: 1px solid #aeb0b4;
		float: right;
		width: 700px;
	}

	&__info {
		padding: 30px 25px 65px 25px;
	}

	&__login {
		margin-bottom: 25px;
	}

	&__login-title {
		font-size: 14px;
		color: #656668;
		margin-bottom: 5px;
		font-weight: 400;
		display: block;
	}

	&__username {
		font-size: 18px;
		color: #1a1c20;
		font-weight: 700;
		display: block;
	}

	#regform-tab {
		display: none;
	}
}

.orders {
	width: 640px;

	.white {
		background: #fff;
		height: 10px;
	}

	.content {
		width: 640px;
	}

	.delimiter {
		height: 10px;
		background: #fff;
	}

	&__card {
		border-bottom: 1px solid #aeb0b4;
	}

	&__footer {
		.total_cost {
			text-align: right;
			font-size: 16px;
			padding: 10px 0;
		}

		.total {
			padding-top: 15px;
		}
	}

	&__number {
		padding: 15px;
	}

	&__view {
		width: 10%;
		text-align: center;

		.btn {
			width: 50px;
			height: 40px;
			position: relative;
			background: none;
		}
	}

	.turn-tick {
		position: absolute;
		width: 16px;
		height: 8px;
		left: 15px;
		top: 17px;
		&:before {
			content: '';
			height: 2px;
			width: 12px;
			display: block;
			background: #1a1c20;
			transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			position: absolute;
			top: 4px;
			left: 6px;
		}

		&:after {
			content: '';
			height: 2px;
			display: block;
			background: #1a1c20;
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			position: absolute;
			top: 4px;
			left: -1px;
			width: 12px;
		}
	}

	&__short {
		border-top: 1px solid #aeb0b4;
		border-bottom: 1px solid #aeb0b4;
		background: #ecf9fd;
		cursor: pointer;

		&:hover {
			background: #fdf1f2;
		}

		.orders-btn--close {
			display: none;
		}

		&.orderActive {
			/* IE10+ */
			background-image: -ms-linear-gradient(top, #EDF9FD 0%, #fbfeff 100%);
			/* Mozilla Firefox */
			background-image: -moz-linear-gradient(top, #EDF9FD 0%, #fbfeff 100%);
			/* Opera */
			background-image: -o-linear-gradient(top, #EDF9FD 0%, #fbfeff 100%);
			/* Webkit (Safari/Chrome 10) */
			background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #EDF9FD), color-stop(100, #fbfeff));
			/* Webkit (Chrome 11+) */
			background-image: -webkit-linear-gradient(top, #EDF9FD 0%, #fbfeff 100%);
			/* W3C Markup */
			background-image: linear-gradient(to bottom, #EDF9FD 0%, #fbfeff 100%);

				&:hover {
					/* IE10+ */
					background-image: -ms-linear-gradient(top, #FDF2F2 0%, #FBFEFF 100%);
					/* Mozilla Firefox */
					background-image: -moz-linear-gradient(top, #FDF2F2 0%, #FBFEFF 100%);
					/* Opera */
					background-image: -o-linear-gradient(top, #FDF2F2 0%, #FBFEFF 100%);
					/* Webkit (Safari/Chrome 10) */
					background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FDF2F2), color-stop(100, #FBFEFF));
					/* Webkit (Chrome 11+) */
					background-image: -webkit-linear-gradient(top, #FDF2F2 0%, #FBFEFF 100%);
					/* W3C Markup */
					background-image: linear-gradient(to bottom, #FDF2F2 0%, #FBFEFF 100%);
				}

			.orders-btn {
				display: none;
			}

			.orders-btn--close {
				display: inline-block;
			}

			.turn-tick {
				&:before {
					left: -1px;
				}

				&:after {
					left: 6px;
				}
			}

			}

		td {
			color: #656668;
		}
	}

	&__item {
		width: 600px;
		margin: 0 auto;
		.image {
			padding: 15px 15px 15px 0;
			width: 10%;
		}

		.title {
			vertical-align: top;
			padding-top: 32px;
			width: 60%;

			a {
				font-size: 14px;
				color: #36849e;
			}
		}

		.count {
			width: 30%;
			.card__table-name {
				width: 75px;
				min-width: 100%;
				color: #1a1c20;
				font-weight: 700;
			}
			.card__table-desc {
				color: #1a1c20;
				font-weight: 700;
			}
		}

	}

}

/* SUCCESS */

#msCart {
	.count {
		width: 30%;
		vertical-align: top;
		padding-top: 20px;
		.card__table-name {
			width: 75px;
			min-width: 100%;
			color: #1a1c20;
			font-weight: 700;
		}
		.card__table-desc {
			color: #1a1c20;
			font-weight: 700;
		}
	}
	td.image {
		padding-left: 0;
	}
}

/* CONTACTS */

.contacts {
	&__content {
		width: 50%;
		float: left;
	}
	&__map {
		width: 50%;
		float: left;
	}
	.m5 {
		margin-bottom: 30px;
	}
}

/* MEDIA */

@media screen and (max-width: 961px) {

	.main-wrapper {
		min-width: 960px;
		// padding: 0px 10px;
	}

	.perspective .outer-nav {
		display: none;
	}

	.perspective.modalview .outer-nav {
		display: block;
	}

	.outer-nav.left {
		left: 0;
	}

	.enter-form__form {
		display: block;
		padding: 0px 90px;
	}

	.enter-form__reg {
		position: absolute;
		bottom: 25px;
		left: 90px;
	}

	.enter-form__forgot {
		padding-right: 90px;
	}

	.enter-form__input {
		display: block;
		width: 100%;
		margin-bottom: 15px;
	}

	.enter-form__submit {
		width: 100%;
	}

	.show-form .header {
		top: 225px;
	}
}




